<template>
  <div
    v-if="
      !props.params.node?.group && props.params.node?.rowPinned !== 'bottom' && props.params.node?.rowPinned !== 'top'
    "
    class="elv-financials-cell-address-main"
  >
    <img
      v-if="props.params.data?.platform?.logo"
      class="elv-financials-cell-address-main__logo"
      :src="props.params.data?.platform?.logo"
      :alt="props.params.data?.platform?.name"
    />
    <div class="elv-financials-cell-address-main__address" :title="identityText">
      {{
        identityText !== '-' && props.params.value?.tableType !== 'CounterParty'
          ? middleEllipsis(identityText)
          : identityText
      }}
    </div>
    <img
      v-if="
        (props.params.data?.platform?.type === 'CHAIN' ||
          props.params.data?.platform?.type === 'BANK' ||
          props.params.data?.platform?.type === 'EXCHANGE' ||
          props.params.data?.platform?.type === 'CUSTODY' ||
          props.params.data?.platform?.type === 'PAYMENT' ||
          props.params.data?.entityAccount?.platform?.type === 'CHAIN' ||
          props.params.data?.entityAccount?.platform?.type === 'BANK' ||
          props.params.data?.entityAccount?.platform?.type === 'CUSTODY' ||
          props.params.data?.entityAccount?.platform?.type === 'PAYMENT' ||
          props.params.data?.entityAccount?.platform?.type === 'EXCHANGE') &&
        identityText !== '-'
      "
      src="@/assets/img/copy.png"
      alt="copy"
      class="elv-financials-cell-address-main__copy"
      @click="onClickedCopy(identityText)"
    />
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import { middleEllipsis } from '@/lib/utils'

const { t } = useI18n()
const { toClipboard } = useClipboard()

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const identityText = computed(() => {
  // eslint-disable-next-line no-nested-ternary
  return props.params.data?.entityAccount?.identity
    ? props.params.data?.entityAccount?.identity
    : // eslint-disable-next-line no-nested-ternary
      props.params.data?.identity
      ? props.params.data?.identity
      : props.params.data?.address
        ? props.params.data?.address
        : (props.params.data?.value ?? props.params.data?.cardNumber ?? '-')
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}
</script>
<style lang="scss">
.elv-financials-cell-address-main {
  display: flex;
  align-items: center;
  cursor: pointer;

  .elv-financials-cell-address-main__logo {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    // border-radius: 50%;
  }

  .elv-financials-cell-address-main__address {
    height: 16px;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
  }

  .elv-financials-cell-address-main__copy {
    padding-left: 4px;
    width: 12px;
    height: 12px;
  }

  .elv-financials-cell-address-main__exchange {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }
}
</style>
